// Global Clarilog
html,
body,
app-root {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
a:visited,
a:hover,
a:link,
a:active {
  color: #000;
}

html {
  font-family: 'Arial';
}
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
// Supprime la bordure de focus.
*:focus {
  outline: 0;
}
.cl-dropdown-popup {
  padding: 0;
}
.cl-state-focused {
  background-color: rgb(228, 228, 228);
}

.clc-massive-edit {
  max-height: 100vh !important;
  .dx-popup-normal {
    max-height: 100vh !important;
  }
}
