// Couleur de la font blanche
$font-color-light: rgb(255, 255, 255);
// Couleur du menu du haut
$background-color-dark: var(--headband); // rgb(2, 75, 124); //rgb(0, 55, 79);
$background-color-dark-hover: var(
  --headband-10
); //rgb(2, 60, 100); //rgb(0, 67, 96);
$background-color-dark-active: var(--headband-50); //rgb(2, 50, 80);

$input-height: 28px;
$item-height: 36px;

$nav-bar-height: 50px;

$border-color: #d6d6d6;

@mixin text-editor($isEditor, $transparent: false) {

  @if $transparent {
    background-color: transparent !important;
  } @else {
    background-color: white !important;
  }
  height: $input-height;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  @if $isEditor {
    background-color: var(--input) !important;
    &.dx-editor-underlined:after {
      border-bottom-width: 0px;
    }
  } @else {
    &.dx-editor-underlined:not(.dx-dropdowneditor-active):after {
      border-bottom-color: var(--input-hover) !important;
    }
    &.dx-dropdowneditor-active:after {
      border-bottom-color: var(--primary) !important;
    }
  }
  &:not(.dx-state-readonly) {
    &.dx-state-active,
    &.dx-state-focused {
      @if $isEditor {
        background-color: var(--input-hover) !important;
      } @else if $transparent == false {
        background-color: #fff !important;
      } @else {
        background-color: transparent !important;
      }
      &:before {
        border-bottom: 1px solid var(--primary) !important;
      }
    }
    &.dx-state-hover {
      &:not(.dx-dropdowneditor-active) {
        @if $isEditor {
          background-color: var(--input-hover) !important;
        } @else if $transparent == false {
          background-color: #fff !important;
        } @else {
          background-color: transparent !important;
        }
        &:after {
          border-bottom: 1px solid var(--input-border) !important;
        }
      }
      &.dx-dropdowneditor-active {
        &:after {
          border-bottom: 1px solid var(--primary) !important;
        }
      }
    }
  }

  &.dx-invalid.dx-state-active:before,
  &.dx-invalid.dx-state-focused:before {
    border-top: 0;
    border-bottom: 1px solid var(--input-error) !important;
  }
  // Permet d'avoir un fond gris dans les zone de texte en read only
  &.dx-state-readonly {
    background-color: var(--input-disabled) !important;
    &.dx-state-active,
    &.dx-state-focused {
      @if $transparent {
        background-color: transparent !important;
      } @else {
        background-color: var(--input-disabled) !important;
      }
      &:before {
        border-bottom: 1px solid var(--input-disabled) !important;
      }
    }
    &.dx-state-hover {
      background-color: var(--input-disabled) !important;
      &:after {
        border-bottom: 1px solid var(--input-disabled) !important;
      }
    }
    &:after {
      border-bottom: 1px solid var(--input-disabled) !important;
    }
  }
  @if $isEditor {
    .dx-placeholder {
      top: -5px;
    }
  }
  // }
}
button {
  border-radius: 0px;
}
@mixin button($fontSize: 9pt) {
  // Pour tous les boutons ou autres composants
  & .dx-icon:not(.dx-icon-spindown) {
    font-size: $fontSize;
    letter-spacing: 0;
  }
  border-width: 0;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  &.default {
    .dx-button-text {
      color: var(--secondary-font);
    }
    .dx-icon {
      color: var(--secondary-font) !important;
    }
    background-color: var(--primary) !important;
  }
  & .dx-button-text {
    color: var(--primary-font);
    font-size: 9pt;
    letter-spacing: 0;
  }
  // État hover
  &.dx-state-hover {
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: var(--secondary) !important;
    .dx-button-text {
      color: var(--input-font-hover) !important;
    }
    .dx-icon {
      color: var(--input-font-hover) !important;
    }
  }
  // État focused
  &:not(.dx-state-hover):not(.dx-item-selected).dx-state-focused{
    -webkit-box-shadow: none;
    box-shadow: none;
   /* background-color: var(--primary) !important;*/
   background-color: var(--secondary-font) !important;
    .dx-button-text {
    /*  color: var(--secondary-font) !important;*/
    color: var(--primary-font) !important;
    }
    .dx-icon {
      color: var(--secondary-font) !important;
    }
  }

  &:not(.dx-state-hover).dx-state-active,
      // Pour le dropdown
      &[aria-expanded="true"],
      &.dx-item-selected {
    background-color: var(--primary) !important;
    .dx-button-text {
      color: var(--secondary-font) !important;
    }
    .dx-icon {
      color: var(--secondary-font) !important;
    }
  }
  .dx-inkripple-wave {
    background-color: var(--primary-10);
  }
  .dx-inkripple {
    display: none !important;
  }
}
@mixin button-editor() {
  & .dx-widget {
    .dx-texteditor-buttons-container {
      & .dx-clear-button-area .dx-icon-clear {
        color: var(--input-border);
        &:hover {
          color: var(--primary-font);
        }
      }
      & .dx-button {
        margin: 0 2px;
      }
      & .dx-button,
      & .dx-button-normal {
        background-color: transparent;

        &:hover {
          background-color: var(--input-border);
        }
        & .dx-inkripple-wave {
          background-color: transparent;
        }
      }
      & .dx-button-has-icon .dx-icon {
        width: auto !important;
      }
      & .dx-button-normal.dx-dropdowneditor-button {
        width: 24px;
        min-width: 24px;
      }
      & .dx-numberbox-spin-button {
        &:hover {
          background-color: var(--input-border);
        }
      }
    }
  }
}