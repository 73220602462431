@use '@angular/material' as mat;

@include mat.core();

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat.m2-define-typography-config(
  $font-family: 'Arial',
  $headline-4:
    mat.m2-define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $headline-3:
    mat.m2-define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  $headline-2:
    mat.m2-define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
  $headline-1: mat.m2-define-typography-level(34px, 40px, 400),
  $headline-5: mat.m2-define-typography-level(24px, 32px, 400),
  $headline-6: mat.m2-define-typography-level(20px, 32px, 500),
  $subtitle-1: mat.m2-define-typography-level(16px, 28px, 400),
  $subtitle-2: mat.m2-define-typography-level(15px, 24px, 400),
  $body-2: mat.m2-define-typography-level(14px, 24px, 500),
  $body-1: mat.m2-define-typography-level(14px, 20px, 400),
  $caption: mat.m2-define-typography-level(12px, 20px, 400),
  $button: mat.m2-define-typography-level(14px, 14px, 500),
);

$my-theme: mat.m2-define-light-theme(
  (
    typography: $custom-typography,
  )
);

@include mat.all-component-themes($my-theme);

/* Line-height must be unit-less fraction of the font-size. $input: mat-typography-level(inherit, 1.125, 400), */

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-label {
  height: 36px !important;

  &.mdc-tab--active {
    opacity: 1 !important;
  }
}

.mat-mdc-tab-link {
  height: 36px !important;

  &.mdc-tab--active {
    opacity: 1 !important;
  }
}

.mat-mdc-tab-link.mdc-tab--active .mdc-tab__text-label {
  /* color: var(--mat-tab-header-active-label-text-color); */
  color: black !important;
}

.mat-mdc-button,
.mat-mdc-unelevated-button,
.mat-mdc-icon-button,
.mat-mdc-outlined-button {
  border-radius: 0 !important;
}

.mdc-button__label {
  width: 100% !important;
}
